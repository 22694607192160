/////////////eeeeeeesttt
/////////////eeeeeeesttt
/////////////eeeeeeesttt
import {isEmpty} from "lodash";

import {useOrderStore} from "../../store/order/order";

import cartBtnImg from '../../public/cartButton.svg'
import './mobileCartButton.css'

export default function CartButton({opened}) {
    const {orderData} = useOrderStore()
    return (
        <nav className='cart-mobile-button-wrapper' onClick={() => opened()}>
            <div className='cart-mobile-button'>
                <img src={cartBtnImg}  alt={'button'} width={45} />
                {!isEmpty(orderData) && orderData.order.product_count > 0 ?
                    <div className='cart-mobile-count'>
                        {orderData.order.product_count}
                    </div>
                    : ''}
            </div>
        </nav>
    )
}