import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {GetAllProducts} from "./productsAPI";

export const useProductsStore = create(devtools((set) => (
    {
        products: {},
        setProductsData: (data) => {
            set({products: data})
        },
        isFetchedProducts: false,
        setIsFetchedProducts: (bool) => {
            set({isFetchedProducts: bool})
        },
        isOpenProductsPopup: false,
        setIsOpenProductsPopup: (bool) => {
            set({isOpenProductsPopup: bool})
        },

        GetProductsAPI: async () => {
            await GetAllProducts().then(function (response) {
                if (response.data.status) {
                    set({products: response.data.data.products})
                    set({isFetchedProducts: true})
                } else {
                    set({products: {}})
                    set({isFetchedProducts: false})
                }
            }).catch(function (error) {
                set({products: {}})
                set({isFetchedProducts: false})
            })
        }
    }
    ),
    {
        name: "products-storage"
    }
    )
)


