import {useCallback,  useState} from "react";
import {Transition} from "react-transition-group";
import {debounce} from "lodash";


import {useGeoStore} from "../../store/geo/geo";
import './PickStreet.css'


import {useRestaurantStore} from "../../store/restaurant/restaurant";
import {GetAllProducts} from "../../store/products/productsAPI";
import {useProductsStore} from "../../store/products/products";
import {useOrderStore} from "../../store/order/order";



// import Portal from "@/hooks/Portal";



export default function PickStreet({opened, onClose}) {
    const {allStreetsList,
         setStreetsList, FindAddressAPI,
        errorMessage, SetAddressAPI} = useGeoStore()
    const {setProductsData, setIsFetchedProducts} = useProductsStore()


    const [streetString, setStreetString] = useState('')
    const [streetId, setStreetId] = useState('')
    const [isStartSearchStreet, setIsStartSearchStreet] = useState(false)
    const [isSelectStreet, setIsSelectStreet] = useState(false)



    const updateSearchValues = useCallback(
        debounce((str) => {
            FindAddressAPI(str)
        }, 1000) , [])

    const handleChangeInput = (e) => {
        setStreetString(e.target.value)
        if (e.target.value.length > 0) {
            setIsStartSearchStreet(true)
            updateSearchValues(e.target.value)
        } else {
            setStreetsList([])
            setIsStartSearchStreet(false)
            updateSearchValues.flush()
        }
    }
    return (

        <Transition
            in={opened}
            timeout={200}
            unmountOnExit={true}
        >
            {(state) => (
                <div className={`popup-container ${state}`}>
                    <div className='popup-overlay' onClick={() => onClose()}/>
                    <div className={`popup-content`}>

                        <h1 className='popup-title'>Ваш адрес</h1>
                        <p className='popup-subtitle-info'>Так мы сможем понять из какого ресторана доставлять вам еду и сможем показать меню :)</p>
                        <label className='popup-input-number'>
                            <h5 className='popup-subtitle'>Улица</h5>
                            <div className='street-drop-menu'>
                                <input className={`street-input-area ${isStartSearchStreet}no-bottom-border`}
                                       placeholder='Пушкина...'
                                       autoFocus={true}
                                       value={streetString}
                                       onChange={e => {
                                           handleChangeInput(e)
                                       }}
                                />
                                <div className='stereet-items'>
                                    {   isStartSearchStreet ?
                                        allStreetsList !== undefined ?
                                            (
                                                allStreetsList.map(item => <div className='street-item'
                                                                               key={item.id}
                                                                               onClick={() => {
                                                                                   console.log(111, item)
                                                                                   const dict = {
                                                                                       position: item.position,
                                                                                       address: item.name,
                                                                                       kind: item.kind,
                                                                                       formattedAddress: item.formattedAddress,
                                                                                       description: item.description,
                                                                                   }
                                                                                   // const dict = {
                                                                                   //     position: ' w',
                                                                                   //     address: 's',
                                                                                   //     kind: 'ss',
                                                                                   //     formattedAddress: 'sd',
                                                                                   //     description: 'q',
                                                                                   // }
                                                                                   SetAddressAPI(dict)
                                                                                   setStreetString(item.name)
                                                                                   setStreetId(item.id)
                                                                                   setIsStartSearchStreet(false)
                                                                                   setIsSelectStreet(true)
                                                                               }
                                                                               }
                                                >{item.name}</div>)
                                            ) : '' : ''
                                    }
                                </div>
                            </div>
                            <div style={{marginTop: '10px', color: 'red'}}>{errorMessage}</div>
                        </label>

                    </div>

                    )
                </div>
            )}


        </Transition>

    )
}


