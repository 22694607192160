import './Footer.css'


export default function Footer() {

    return (
        <footer className='footer-wrapper'>
            <section className='footer'>
                <section className='footer-first-part'>

                    <aside className='footer-links'>
                        <a href="https://docs.google.com/document/d/1T4QeEzneAVXaM6XIj1J6NkEtjQwTXxklOz7O83DFu-M/edit?usp=sharing" target={'_blank'}>Пользовательское соглашение</a>
                        <a href="https://docs.google.com/document/d/1JE4g1byv2IIzU5Z59GZnmLxcXxou_ByqIX7SDBRRlis/edit?usp=sharing" target={'_blank'}>Политика обработки персональных данных</a>
                        <a href="https://docs.google.com/document/d/1nJTfQrLQ7YmzwDPuVuqRWJn-ZfL41_TWCjL08UX2iJc/edit?usp=sharing" target={'_blank'}>Публичная оферта</a>
                        {/*<a href="https://youtube.com">Телеграмм</a>*/}
                        {/*<a href="https://youtube.com">Группа в ВК</a>*/}
                    </aside>

                    <aside className='footer-info'>
                        <span className='footer-error'>Возникла ошибка? Пишите на почту poskoreev@mail.ru<br/>Или в телеграм @ilyadischenko</span>
                        <span className='footer-copyright'>ИП Игнашин Максим Эдуардович</span>
                    </aside>

                </section>

                <section className='footer-second-part'>
                    <h3 className="footer-cart-title">Зона доставки</h3>
                    {/*<a href="https://yandex.ru/maps/?um=constructor%3A9af3de3314adf98ce1e1a82378b0afd7e8afbbbc0c4af6e851eaadfc2306a861&amp;source=constructorStatic"*/}
                    {/*   target="_blank"*/}
                    {/*   className="footer-cart"*/}

                    {/*><img*/}
                    {/*    src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A9af3de3314adf98ce1e1a82378b0afd7e8afbbbc0c4af6e851eaadfc2306a861&amp;width=550&amp;height=450&amp;lang=ru_RU"*/}
                    {/*    alt=""/>*/}
                    {/*</a>*/}

                    {/*<a href="https://yandex.ru/maps/?um=constructor%3A9af3de3314adf98ce1e1a82378b0afd7e8afbbbc0c4af6e851eaadfc2306a861&amp;source=constructorStatic"*/}
                    {/*   target="_blank"*/}
                    {/*   className="footer-cart"*/}
                    {/*><img*/}
                    {/*    src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A9af3de3314adf98ce1e1a82378b0afd7e8afbbbc0c4af6e851eaadfc2306a861&amp;width=450&amp;height=450&amp;lang=ru_RU"*/}
                    {/*    alt=""/></a>*/}

                    <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A9af3de3314adf98ce1e1a82378b0afd7e8afbbbc0c4af6e851eaadfc2306a861&amp;source=constructor"
                        className="footer-cart-frame"></iframe>
                </section>

            </section>

        </footer>

    )

}

