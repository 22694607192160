import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'


import {
    AddPromocodeToCart, ChoosePaytype,
    DecreaseProductToCart,
    GetOrderInfo,
    RemoveProductFromCart,
    RemovePromocodeFromCart
} from "./orderAPI";



export const useOrderStore = create(devtools((set) => (
    {
        orderData: {},
        setOrderData: (data) => {
            set({orderData: data})
        },
        house: '',

        entrance: '',
        setEntrance: (data) => {
            set({entrance: data})
        },
        appartment: '',
        setAppartment: (data) => {
            set({appartment: data})
        },
        floor: '',
        setFloor: (data) => {
            set({floor: data})
        },
        comment: '',
        setComment: (data) => {
            set({comment: data})
        },

        clearOrderData: () => {
            set({comment: ''})
            set({floor: ''})
            set({appartment: ''})
            set({entrance: ''})
            set({house: ''})
            set({orderData: {}})
        },

        getOrderAPI: (id) => {
            GetOrderInfo(id).then(function (response) {
                if (response.data.status) {
                    set({orderData: response.data.data})
                } else {
                    set({orderData: {}})
                }
            }).catch(function (error) {
                set({orderData: {}})
            })
        },

        decreaseProductToCartAPI: (id) => {
            DecreaseProductToCart(id).then(function (response) {
                    if(response.data.status) {
                        set({orderData: response.data.data})
                    } else {
                        alert(response.data.errorMessage)
                    }
            })
        },
        RemoveProductFromCartAPI: (id) => {
            RemoveProductFromCart(id).then(function (response) {
                if(response.data.status) {
                    set({orderData: response.data.data})
                } else {
                    alert(response.data.errorMessage)
                }
            })
        },

        AddPromocodeAPI: (promocode, setter = undefined) => {
            AddPromocodeToCart(promocode).then(function (response) {
                if(response.data.status) {
                    set({orderData: response.data.data})
                } else {
                    alert(response.data.errorMessage)
                    if (setter !== undefined) setter(true)
                }
            })
        },

        RemovePromocodeAPI: () => {
            RemovePromocodeFromCart().then(function (response) {
                if(response.data.status) {
                    set({orderData: response.data.data})
                } else {
                    alert(response.data.errorMessage)
                }
            })
        },

        ChoosePaytypeAPI: (paytypeId, setPaytypeId) => {
            ChoosePaytype(paytypeId).then(function (response) {
                setPaytypeId(response.data.data.id)
            })
        },

        haveActiveOrders: false,
        setHaveActiveOrders: (bool) => {
            set({haveActiveOrders: bool})
        },
        activeOrders: [],
        setActiveOrders: (bool, orders) => {
            set({activeOrders: orders})
            set({haveActiveOrders: bool})
        },
        setActiveOrdersForAPI: (orders) => {
            set({activeOrders: orders})
        },

        isOpenPopupActiveOrders: false,
        setIsOpenPopupActiveOrders: (bool) => {
            set({isOpenPopupActiveOrders: bool})
        }
    }
    ),
    {
        name: "order-storage"
    }
    )
)



