
import {url} from '../../config'

import {useEffect, useRef} from "react";
import {useOrderStore} from "./order";
import {useUserStore} from "../user/user";


export const EventSourcing = () => {
    const {setActiveOrders, haveActiveOrders} = useOrderStore()
    const {isLogin} = useUserStore()
    const eventSource = useRef()
    const onUpdateOrders = (data) => {
        const response = JSON.parse(data.data)
        if (!isLogin) {
            eventSource.current.close()
            console.log('close')
            setActiveOrders(false, {})
            return undefined
        }
        if (!response.haveActiveOrders) {
            eventSource.current.close()
            setActiveOrders(false, {})
        } else {
            setActiveOrders(true, response.orders)
        }

    }

    useEffect(() => {

        if (haveActiveOrders && isLogin) {
            tryToConnect()
        } else {
            if (eventSource.current !== undefined) {
                eventSource.current.close()
                setActiveOrders(false, {})
            }
        }
    }, [haveActiveOrders, isLogin]);




    const tryToConnect = () => {
        eventSource.current = new EventSource(
            `${url}/api/v1/orders/connecttoorderstream`, {withCredentials: true}
        )
        eventSource.current.addEventListener('error', function (e) {
            eventSource.close()
        })

        eventSource.current.addEventListener('message', function (e) {
            let data = JSON.parse(e.data)
            setActiveOrders(true, data.orders)
        })

    }

    function onOpen(e) {
        console.log('Успешное соединение');
    };

    function onClose(e) {
        console.log('Соединение закрыто. Попытка повторного подключения');
        // setTimeout(() => tryToConnect(), 10000); // повторное подключение через 1 секунду
    }

    const onMessage = (event) => {
        onUpdateOrders(event)
    }

    function onError(e) {
        console.log('Ошибка соединения');
        console.log(333, e);
        eventSource.current.close()
        // tryToConnect()
    }
}