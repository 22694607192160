import {useState} from "react";

import {Transition} from "react-transition-group";
import {useUserStore} from "../../store/user/user";

import './login.css'
import backButton from './../../public/backButton.svg'
import axios from "axios";
import {url} from "../../config";
import {useOrderStore} from "../../store/order/order";

// import Portal from "@/hooks/Portal";


function GetLoginHtml(
) {
    const [isLoading, setIsLoading] = useState(false)

    const {
        inputNumber, setInputNumber, setIsOpenLoginPopup, setCodeSended
    } = useUserStore()

    const sendCode = async () => {
        if (inputNumber.length === 10 && !isLoading) {
            setIsLoading(true)
            await axios.post(`${url}/api/v1/users/login?number=${('8' + inputNumber).toString()}`)
                .then(function (response) {
                    if (response.data.status) {
                        setCodeSended(true)

                    } else {
                        alert(response.data.errorMessage)
                    }
                }).catch(function () {
                    setCodeSended(false)
                    setIsOpenLoginPopup()
                })
            setIsLoading(false)
        }
        return null
    }


    return (
        <>
            <h1 className='login-title'>Вход на сайт</h1>
            <div className='login-instruction'>Мы отправим вам код в смс</div>

            <label className='login-input-number'>
                <h5 className='login-subtitle'>Номер телефона</h5>
                <div className='login-input-number-div'>
                    <span className='login-input-placeholder'>+7</span>
                    <input className='login-input-number-area'
                           autoFocus={true}
                           autoComplete={'off'}
                        // type={'number'}
                           value={inputNumber}
                           onChange={(e) => setInputNumber(e.target.value)}
                    />
                </div>
            </label>

            <button className={`login-send-code `}
                    onClick={() => sendCode()}
                    disabled={inputNumber.length !== 10 || isLoading}
            >Выслать код
            </button>
            <p className='login-user-info'>Продолжая, вы соглашаетесь &nbsp;
                <a className='green'
                    href={'https://docs.google.com/document/d/1JE4g1byv2IIzU5Z59GZnmLxcXxou_ByqIX7SDBRRlis/edit?usp=sharing'}
                    target={'_blank'}
                >
                    со сбором и обработкой персональных данных</a>&nbsp;и&nbsp;
                <a
                    href={'https://docs.google.com/document/d/1T4QeEzneAVXaM6XIj1J6NkEtjQwTXxklOz7O83DFu-M/edit?usp=sharing'}
                    target={'_blank'}
                className='green'>пользовательским соглашением</a>
            </p>

        </>
    )
}

const GetCodeHtml = () => {
    const [code, setCode] = useState('')

    const {GetUserInfoAPI, setUserdata, setCodeSended, setIsLogin, setErrorMessage,
        setIsOpenLoginPopup, setInputNumber, inputNumber, errorMessage} = useUserStore()
    const {setHaveActiveOrders, setActiveOrdersForAPI} = useOrderStore()

    const ConfirmCodeAPI = async () => {
        await axios.post(`${url}/api/v1/users/confirmcode?number=${('8' + inputNumber).toString()}&code=${code}`)
            .then(function (response) {
                if (response.data.status) {
                    setUserdata(response.data.data)
                    setCodeSended(false)
                    setInputNumber('')
                    setIsLogin(true)
                    setErrorMessage('')
                    setIsOpenLoginPopup(false)
                    GetUserInfoAPI()
                    CheckActiveOrdersAPI()

                } else {
                    setErrorMessage(response.data.errorMessage)
                }
            }
        ).catch(function (error) {
            setCodeSended(false)
            setIsOpenLoginPopup(false)
        })
    }
    async function CheckActiveOrdersAPI (){
        await axios.get(`${url}/api/v1/orders/checkActiveOrders`).then(function (response) {
            if (response.data.status) {
                if (!response.data.data.haveActiveOrders) {
                    setHaveActiveOrders(false)
                    setActiveOrdersForAPI([])
                } else {
                    setHaveActiveOrders(true)
                    setActiveOrdersForAPI(response.data.data.orders)
                }
            }
        }).catch(function (error) {
            setHaveActiveOrders(false)
            setActiveOrdersForAPI([])
        })
    }
    return (
        <>
            <h1 className='login-title flex' onClick={() => {
                setCodeSended(false)
                setErrorMessage('')
            }}>
                <img src={backButton} className='login-back-button' alt={'К авторизации'} />
                Введите код
            </h1>
            <div className='login-instruction'>Мы отправили вам код в смс</div>

            <label className='login-input-number'>
                <h5 className='login-subtitle'>Код</h5>
                <input className='login-input-number-area'
                       placeholder='Ваш код'
                       autoFocus={true}
                       // type={'number'}
                       value={code}
                       onChange={(e) => setCode(e.target.value)}
                />
                <span className={'login-error-message'}>{errorMessage}</span>
            </label>

            <button className={`login-send-code ${code.length > 3 ? 'login-button-active' : null}`}
                    onClick={() => code.length > 3 ? ConfirmCodeAPI() : null}>Подтвердить код
            </button>
            <p className='login-user-info'>Продолжая, вы соглашаетесь &nbsp;
                <a className='green'
                   href={'https://docs.google.com/document/d/1JE4g1byv2IIzU5Z59GZnmLxcXxou_ByqIX7SDBRRlis/edit?usp=sharing'}
                   target={'_blank'}
                >
                    со сбором и обработкой персональных данных</a>&nbsp;и&nbsp;
                <a
                    href={'https://docs.google.com/document/d/1T4QeEzneAVXaM6XIj1J6NkEtjQwTXxklOz7O83DFu-M/edit?usp=sharing'}
                    target={'_blank'}
                    className='green'>пользовательским соглашением</a>
            </p>
        </>
    )
}

export default function LoginComponent({opened, onClose}) {
    const {codeSended} = useUserStore()


    const onCloseWrapper = (event) => {
        if (event.target.classList.contains('login-overlay')) onClose()
    }

    return (
        // <Portal>
        <Transition
        in={opened}
        timeout={200}
        unmountOnExit={true}
        >
            {(state) => (
                <div className={`login-container ${state}`}>
                    <div className='login-overlay' onClick={onCloseWrapper}/>
                    <div className={`login-content`}>
                        { !codeSended ?
                            <GetLoginHtml />
                            :
                            <GetCodeHtml />
                            }
                    </div>
                </div>
            )}


        </Transition>
        // </Portal>
    )
}