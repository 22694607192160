import {useState} from "react";

import {Link} from "react-router-dom"

import ProfileComponent from '../../components/profile/Profile'
import MobileCartButton from '../../components/mobileCartButton/MobileCartButton'

import './header.css'
import logocircle from '../../public/logocircle.svg'
import logoprofile from '../../public/logoprofile.svg'

import {useUserStore} from "../../store/user/user";
import {useAppStore} from "../../store/app/app";

import {GeoComponent} from "../geoinfo/GeoInfo";
import s from "../phoneNumber/Phone.module.scss";
import phone from "../../public/phone.svg";
import clock from "../../public/clock.svg";



export const Header = () => {
    const {isLogin,  setIsOpenLoginPopup} = useUserStore()

    const {setCartPopupOpen} = useAppStore()
    
    const [openedProfile, setProfileOpened] = useState(false);

    const copyTextToClipboard = async (text) => {
        await navigator.clipboard.writeText(text);
    };

    return (
        <header className='header-wrapper'>
            <div className='header'>
                <div className='header-block'>

                    <div className='header-info'>
                        <div className='header-info-item'
                             // style={{marginBottom: '20px'}}
                             onClick={() => copyTextToClipboard('+79066603535')}>
                            <img
                                src={phone}
                                alt="Закажите по телефону"
                                className='header-info-img'
                            />
                            +7 (906) 660-35-35
                        </div>

                        <div className='header-info-item'>
                            <img
                                src={clock}
                                alt="Время работы"
                                className='header-info-img'
                            />
                            11:00 - 22:00
                        </div>
                    </div>

                    <div className='header-main-part'>

                        <Link to={'/'} className='logo'>
                            <img src={logocircle} alt={'Поскореев - Доставка еды в течении часа по вашему городу'}
                                 className={'logo-img'}/>
                            <div className='logo-text'>
                                <span className='logo-title'>Поскореев</span>
                                <span className='logo-subtitle'>Доставка пиццы</span>
                            </div>
                        </Link>

                        <GeoComponent />

                        <div className='header-right'>
                            {isLogin ? (
                                <button className='header-login' onClick={() => {
                                    setProfileOpened(true)
                                }}>
                                    <img src={logoprofile} alt="Профиль" className='profile-logo'/>

                                    Кабинет</button>
                            ) : (
                                <button className='header-login login-border' onClick={() => {
                                    setIsOpenLoginPopup(true)
                                }}>
                                    Войти
                                </button>
                            )}

                        </div>
                    </div>

                </div>
            </div>


            <ProfileComponent opened={openedProfile} onClose={() => setProfileOpened(false)} />
            <MobileCartButton opened={() => setCartPopupOpen(true)}/>

        </header>
    )
}


