import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'

export const useAppStore = create(devtools((set) => (
    {
        cartToast: '',
        setCartToast: (data) => {
            set({cartToast: data})
        },
        cartToastHash: 0,
        setCartToastHash: () => {
            set({cartToastHash: Math.random()})
        },
        headerHeight: [],
        setHeaderHeight: (data) => {
            set({headerHeight: data})
        },

        isCartPopupOpen: false,
        setCartPopupOpen: (bool) => {
            set({isCartPopupOpen: bool})
        },

        viewCookiePopup: true,
        setViewCookiePopup: (bool) => {
            set({viewCookiePopup: false})
        },
    }
    ),
    {
        name: "app-storage"
    }
    )
)


