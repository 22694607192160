import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {GetRestaurantsInfo, GetRestaurantsPaytypes} from "./restaurantAPI";

export const useRestaurantStore = create(devtools((set) => (
    {
        isPickStreetPopupOpen: false,
        setIsPickStreetPopupOpen: (bool) => {
            set({ isPickStreetPopupOpen: bool})
        },

        restaurantData: {},
        setRestaurantData: (data) => {
            set({ restaurantData: data})
        },

        isRestaurantDataValid: false,
        setIsRestaurantDataValid: (data) => {
            set({ isRestaurantDataValid: data})
        },

        getRestaurantInfoAPI: () => {
            GetRestaurantsInfo().then(function (response) {
                if (response.data.status) {
                    set({
                        restaurantData: response.data.data,
                        isRestaurantDataValid: true
                    })
                } else {
                    set({
                        restaurantData: {},
                        isRestaurantDataValid: false,
                    })
                }

            }).catch(function (error) {
                set({
                    restaurantData: {},
                    isRestaurantDataValid: false,
                })
            })

        },

        restaurantPaytypes: [],
        paytypeCursor: 0,
        paytypeId: undefined,
        setPaytypeId: (id) => {
            set({paytypeId: id})
        },
        setPaytypeCursor: (id) => {
            set({paytypeCursor: id})
        },


        getRestaurantPaytypesAPI: () => {
            GetRestaurantsPaytypes().then(function (response) {
                set({
                    restaurantPaytypes: response.data,
                })
            }).catch(function (error) {
                set({
                    restaurantPaytypes: [],
                })
                alert('Ошибка при получении способов оплаты')
            })
        },

    }
    ),
    {
        name: "restaurant-storage"
    }
    )
)


