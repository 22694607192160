import axios from 'axios'

import {url} from '../../config'

axios.defaults.withCredentials = true


export async function GetUserInfo (){
    return await axios.get(`${url}/api/v1/users/`, {withCredentials: true})
}

export async function ExitFromAccount (){
    return await axios.post(`${url}/api/v1/users/exit`)
}