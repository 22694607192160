import axios from 'axios'

import {url} from '../../config'

axios.defaults.withCredentials = true
export async function GetCities (number){
    return await axios.get(`${url}/api/v1/restaurants/getcities`)
}

export async function SetCity (number, code){
    return await axios.post(`${url}/api/v1/restaurants/setcity?city=1`)
}

export async function FindAddress(query){
    return await axios.get(`${url}/api/v1/restaurants/findaddres?query=${query}`)
}

export async function SetAddress(dict){

    return await axios.post(`${url}/api/v1/restaurants/setaddress`, {
        "position": dict.position,
        "address": dict.address,
        "kind": dict.kind,
        "description": dict.description,
        "formattedAddress": dict.formattedAddress,
        // "entrance": dict.entrance,
        // "floor": dict.floor,
        // "apartment": dict.apartment,
        // "comment": dict.comment
    })
}

export async function GetStreets (number){
    return await axios.get(`${url}/api/v1/restaurants/getstreets`)
}

export async function SetStreet (id){
    return await axios.post(`${url}/api/v1/restaurants/setstreet?street=${id}`)
}

