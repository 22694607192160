import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {FindAddress, SetAddress} from "./geoAPI";
import {useRestaurantStore} from "../restaurant/restaurant";
import {useOrderStore} from "../order/order";
import {useProductsStore} from "../products/products";

export const useGeoStore = create(devtools((set) => (
    {
        city_id: '',
        city_str: '',
        restaurant_id: '',
        restaurant_str: '',

        street_str: '',
        setStreetStr: (data) => {
            set({street_str: data})
        },

        allStreetsList: [],
        setStreetsList: (data) => {
            set({allStreetsList: data})
        },
        setStreets: (id, str) => {
            set({city_id: id, city_str: str})
        },

        FindAddressAPI: (query) => {
            FindAddress(query).then(function (response) {
                if (response.data.status) {
                    set({allStreetsList: response.data.data.addresses})
                }
            }).catch(function (error) {
                alert("Произошла неизветсная ошибка")
            })
        },

        errorMessage: '',
        SetAddressAPI: (dict) => {
            SetAddress(dict).then(function (response) {
                if (response.data.status) {
                    set({allStreetsList: response.data.data.addresses})
                    set({errorMessage: ''})
                    setIsPickStreetPopupOpen(false)
                    getRestaurantInfoAPI()
                    getOrderAPI()
                    GetProductsAPI()
                } else {
                    set({errorMessage: response.data.errorMessage})
                }
                console.log(response.data)
            }).catch(function (error) {
                alert("Произошла неизветсная ошибка")
            })
        },
    }
    ),
    {
        name: "geo-storage"
    }
    )
)

const {setIsPickStreetPopupOpen, getRestaurantInfoAPI} = useRestaurantStore.getState();
const {getOrderAPI} = useOrderStore.getState();
const {GetProductsAPI} = useProductsStore.getState();
