import {Transition} from "react-transition-group";
import axios from "axios";

import {error_message, url} from "../../config";

import {useUserStore} from "../../store/user/user";
import {useOrderStore} from "../../store/order/order";
import {useRestaurantStore} from "../../store/restaurant/restaurant";

import './profile.css'
import exitSvg from '../../public/exit.svg'




export default function Cart({opened, onClose}) {
    const {userData, setUserdata, setIsLogin, setCodeSended,
        setInputNumber} = useUserStore()
    const {orderData,setOrderData, AddPromocodeAPI, RemovePromocodeAPI, setActiveOrders} = useOrderStore()
    const {setIsPickStreetPopupOpen} = useRestaurantStore()

    const onCloseWrapper = (event) => {
        if (event.target.classList.contains('profile-overlay')) onClose()
    }

    const ExitFromAccountAPI = async () => {
        await axios.post(`${url}/api/v1/users/exit`).then(function (response) {
            if (response.data.status) {
                setUserdata({})
                setIsLogin(false)
                setCodeSended(false)
                setInputNumber('')
                setActiveOrders(false, [])
                setOrderData({})
            } else {
                alert(response.data.errorMessage)
            }
        }
        ).catch(function (error) {
            alert(error_message)
        }).finally(() => {
            onClose()
        })
    }

    return (
        // <Portal>
        <Transition
        in={opened}
        timeout={200}
        unmountOnExit={true}
        >
            {(state) => (
                <div className={`profile-container ${state}`}>
                    <div className='profile-overlay' onClick={onCloseWrapper}/>
                    <div className={`profile-content profile-content-${state}`}>

                        <section className='profile-title-section'>
                            <label className='profile-title'>
                                <span className='profile-title-text'>Личный кабинет</span>
                                <button className='profile-exit' onClick={onClose}>
                                    <img src={exitSvg} alt={'exit'} height={25}/>
                                </button>
                            </label>
                        </section>

                        <section className='profile-info'>
                            <div className='profile-number'>
                                <span className='profile-number-title'>Номер</span>
                                <div className='profile-number-input'>{userData.number}</div>
                            </div>
                        </section>

                        <section className='profile-promocodes'>
                            {userData.promocodes ? userData.promocodes.length > 0 ?
                            <h2 className='profile-promocodes-title'>Ваши промокоды</h2> : '' : ''}
                            <div className='profile-promocodes-row'>

                                {userData.promocodes ? userData.promocodes.length > 0 ?
                                    userData.promocodes.map((item) => {
                                        return (
                                            <div className={`profile-promocode-item ${
                                                Object.keys(orderData).length !== 0 ?
                                                    orderData.promocode.promocode === item.promocode ?
                                                        orderData.promocode.linked ?
                                                            'profile-promocode-item-linked'
                                                            : '' : '' : ''
                                            }`}
                                                 key={item.promocode}>
                                                <div>
                                                    <h3 className='profile-promocode-item-title'>Скидка {item.effect}</h3>
                                                    <p className='profile-promocode-item-description'>{item.description}</p>
                                                </div>

                                                <div>
                                                    <p className='profile-promocode-item-expires'>до {item.expires_at}</p>

                                                    <div className='profile-promocode-appliied-message'>
                                                        {Object.keys(orderData).length !== 0 ?
                                                            orderData.promocode.linked ?
                                                                orderData.promocode.promocode === item.promocode ?
                                                                'Промокод применён'
                                                                : '' : '' : ''
                                                        }
                                                    </div>

                                                    <button className={`profile-promocode-item-button ${
                                                        Object.keys(orderData).length !== 0 ?
                                                            orderData.promocode.linked ?
                                                                orderData.promocode.promocode === item.promocode ?
                                                                'profile-promocode-button-disactive'
                                                                : '' : '' : ''
                                                    }`}
                                                        onClick={() => {
                                                            Object.keys(orderData).length !== 0 ?
                                                                orderData.promocode.promocode === item.promocode ?
                                                                orderData.promocode.linked ?
                                                                RemovePromocodeAPI()
                                                                : AddPromocodeAPI(item.promocode, setIsPickStreetPopupOpen)
                                                                : AddPromocodeAPI(item.promocode, setIsPickStreetPopupOpen)
                                                                : AddPromocodeAPI(item.promocode, setIsPickStreetPopupOpen)

                                                        }}
                                                    >
                                                        {Object.keys(orderData).length !== 0 ?
                                                            orderData.promocode.promocode === item.promocode ?
                                                            orderData.promocode.linked ?
                                                                'Отменить'
                                                                : 'Применить' : 'Применить' : 'Применить'}
                                                    </button>
                                                </div>

                                            </div>
                                        )
                                    }) :
                                    <div className='profile-empty-promocodes'>
                                        У вас пока нет промокодов :(
                                    </div> : ''
                                }

                            </div>
                        </section>

                        <button className='profile-exit-button' onClick={() => ExitFromAccountAPI()}>Выйти</button>
                    </div>
                </div>
            )}


        </Transition>
        /*</Portal>*/
    )
}