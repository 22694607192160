

import s from './GeoInfo.module.scss'

import {useRestaurantStore} from "../../store/restaurant/restaurant";


export default function GeoInfo() {
    const {restaurantData, isRestaurantDataValid, setIsPickStreetPopupOpen, } = useRestaurantStore()

    if (isRestaurantDataValid) {
        return (
            <section className={s.wrapper} onClick={() => setIsPickStreetPopupOpen(true)}>

                <div className={s.street}>
                    {/*<span>Ваша улица: </span>&nbsp;*/}
                    {restaurantData.client_address}
                </div>

                <div className={s.minsum}>
                    Минимальная сумма заказа - {restaurantData.min_sum}р
                </div>
            </section>

        )
    } else {
        return <section className={s.wrapper} onClick={() => setIsPickStreetPopupOpen(true)}>
            <span className={s.title}>Укажите адрес доставки</span>
            <span className={s.subtitle}>Так мы поймём из какого ресторана доставлять вам еду</span>
        </section>
    }
}

export const GeoComponent =  () => {
    const {restaurantData, isRestaurantDataValid, setIsPickStreetPopupOpen, } = useRestaurantStore()
    if (isRestaurantDataValid) {
        return (
            <div className={s.headerWrapper} onClick={() => setIsPickStreetPopupOpen(true)}>
                <div className={s.headerStreet}>
                    {/*<span>Ваша улица: </span>&nbsp;*/}
                    {restaurantData.client_address}
                    {/*<img src={arrow.src} alt={'Продолжить. Заказать доставку еды в Поскореев. Заказать пиццу.'}/>*/}
                </div>

                <div className={s.headerMinsum}>
                    Минимальная сумма заказа - {restaurantData.min_sum}р
                </div>
            </div>
        )
    } else {
        return (
            <div className={s.headerWrapper} onClick={() => setIsPickStreetPopupOpen(true)}>
                <span className={s.headerTitle}>Укажите адрес доставки</span>
                <span className={s.headerSubtitle}>Так мы поймём из какого ресторана доставлять вам еду</span>
            </div>
        )
    }
}

