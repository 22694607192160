import s from './ActiveOrders.module.scss'
import {useOrderStore} from "../../store/order/order";


export const ActiveOrders = () => {
    const {activeOrders, setIsOpenPopupActiveOrders} = useOrderStore()

    if (activeOrders.length === 0){return ''}

    const order = activeOrders.at(-1)


    const statusTranslater = () => {

        if (order.status === 0) {
            return 'Принят'
        } else if (order.status === 1) {
            return 'Готовится'
        } else if (order.status === 2) {
            return 'Готовится'
        } else if (order.status === 3) {
            return 'Доставляется'
        } else if (order.status === 4) {
            return 'Доставлен'
        } else if (order.status === 5) {
            return 'Отменен менеджером'
        } else if (order.status === 6) {
            return 'Ожидает подтверждения'
        }
    }

    return (
        <section className={s.wrapper} onClick={() => setIsOpenPopupActiveOrders(true)}>
            <p className={s.title}>Активные заказы</p>

            <aside className={s.main}>
                <div className={s.firstRow}>
                    <span>№{order.order_id}</span>
                    <span>{statusTranslater()}</span>
                </div>
                <div className={s.middlePart}>
                    <span>Сумма {order.items.total_sum}р</span>
                    <span>{order.logs.created_at}</span>
                </div>
                <button className={s.button}>Посмотреть заказ</button>
            </aside>
        </section>
    )

}