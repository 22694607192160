import {Transition} from "react-transition-group";
import Portal from "../../hooks/Portal";


export const Popup = ({opened, onClose, children}) => {

    const onCloseWrapper = (event) => {
        if (event.target.classList.contains('popup-overlay')) onClose()
    }

    return (
        <Portal>
        <Transition
            in={opened}
            timeout={200}
            unmountOnExit={true}
        >
            {(state) => (
                <div className={`popup-container ${state}`}>
                    <div className='popup-overlay' onClick={onCloseWrapper}/>
                    <div className={`popup-content`}>
                        {children}
                    </div>
                </div>
            )}


        </Transition>
        </Portal>
    )
}