import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {GetUserInfo} from "./userAPI";



export const useUserStore = create(devtools((set) => (
    {
        userData: {},
        setUserdata: (data) => {
            set({ userData: data})
        },

        inputNumber: "",
        setInputNumber: (number) => {
            if (number.length > 10) {
                return undefined
            }
            set({inputNumber: number})
        },

        isOpenLoginPopup: false,
        setIsOpenLoginPopup: (bool) => {
            set({isOpenLoginPopup: bool})
        },

        isLogin: false,
        setIsLogin: (bool) => { set({ isLogin: bool}) },

        isPickStreet: true,
        setIsPickStreet: (bool) => { set({ isPickStreet: bool}) },

        GetUserInfoAPI: () => {
            GetUserInfo().then(function (response) {
                if (response.data.status) {
                    if (!response.data.data.is_auth) {
                        set({isLogin: false})
                        // setActiveOrders(false, [])
                    } else {
                        set({isLogin: true})
                        set({userData: response.data.data})
                    }
                }}
            ).catch(function (error) {
                set({isLogin: false})
                set({userData: {}})
            })
        },

        codeSended: false,
        setCodeSended: (bool) => { set({codeSended: bool}) },

        errorMessage: '',
        setErrorMessage: (str) => { set({errorMessage: str}) },

    }
    ),
    {
        name: "user-storage"
    }
    )
)



