import s from './CookiePopup.module.scss'
import {useAppStore} from "../../store/app/app";

import exit from '../../public/exit.svg'
export const CookiePopup = () => {
    const {setViewCookiePopup} = useAppStore()

    return (
        <div className={s.wrapper}>
            <div className={s.popup} onClick={() => setViewCookiePopup(false)}>
                <span>Мы используем cookies для работы сайта. Продолжая пользоваться сайтом, вы принимаете условия обработки персональных данных</span>
                <img src={exit} alt="Закрыть" />
            </div>
        </div>
    )
}