import axios from 'axios'

import {error_message, url} from '../../config'


axios.defaults.withCredentials = true
axios.interceptors.response.use( function (response) {
    // console.log(response)
    return response
}, function (error) {
        alert(error_message)
        return Promise.reject(error)
}
)

export async function GetOrderInfo (){
    return await axios.get(`${url}/api/v1/orders/getOrder`)
}

export async function DecreaseProductToCart (id){
    return await axios.put(`${url}/api/v1/orders/decreaseQuantity?menu_id=${id}`)
}

export async function RemoveProductFromCart (id){
    return await axios.delete(`${url}/api/v1/orders/removeFromCart?menu_id=${id}`)
}

export async function AddPromocodeToCart (promocode){
    return await axios.post(`${url}/api/v1/orders/addPromocode?promocode_short_name=${promocode}`)
}
export async function RemovePromocodeFromCart (){
    return await axios.post(`${url}/api/v1/orders/removePromocode`)
}

export async function ChoosePaytype (paytypeId){
    return await axios.post(`${url}/api/v1/orders/choosePaymentType?pay_type=${paytypeId}`)
}

