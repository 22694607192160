import {useRef, useState} from "react";
import ProductCart from "../../components/productCart/ProductCart";
import './category.css'


export default function Category({itemsArray, index}) {

    return (
        <section className='category-section' id={index}>
            <h1 className='category-title'>{itemsArray.type}</h1>

            <main className='items-wrapper'>

                {
                    itemsArray.items.map((item, index) => {
                    return (
                        <MenuItem key={index} item={item} />
                    )
                    })
                }
            </main>

        </section>
    )
}

const MenuItem = ({item}) => {

    const [isOpenProductPopup, setIsOpenProductPopup] = useState(false)
    const [popupData, setPopupData] = useState(false)
    const [cursor, setCursor] = useState(0)
    const [isLoadImg, setIsLoadImg] = useState(true)

    const imgRef = useRef(null)


    function openProductPopup(item) {
        const el = item.in_stock.indexOf(true)
        if (el >= 0){
            setCursor(el)
            setIsOpenProductPopup(true)
            setPopupData(item)
        }
    }

    function checkInStockForProduct (item) {
        return item.in_stock.indexOf(true)
    }

    return (
        <>
        <article key={item.id[0]} className='item' onClick={() => {
            openProductPopup(item)
        }}>
            <main className='item-main'>
                <img src={item.img} className='item-img' alt={`Доставка пиццы в Орле - ${item.title}`}
                     // onLoadStart={() => setIsLoadImg(true)}
                     onLoad={() => setIsLoadImg(false)}
                     ref={imgRef}
                />
                <div className='item-info'>
                    <div className='item-title'>{item.title}</div>
                    {item.description}
                    <button
                        className={checkInStockForProduct(item) >= 0 ? `item-mobile-button` : 'item-mobile-button disactive'}
                        onClick={() => openProductPopup(item)}
                    >
                        {checkInStockForProduct(item) >= 0 ? `от ${item.prices[0]}р` : 'Раскупили'}
                    </button>
                </div>
            </main>
            <footer className='item-footer'>
                <div className='item-price'>{`от ${item.prices[0]}р`}</div>
                <button className={checkInStockForProduct(item) >= 0 ? `item-button` : 'item-button disactive'}
                        onClick={() => openProductPopup(item)}
                >
                    {checkInStockForProduct(item) >= 0 ? 'Добавить' : 'Раскупили'}
                </button>
            </footer>
        </article>
        {
            isOpenProductPopup ? <ProductCart opened={isOpenProductPopup}
                                              onClose={() => setIsOpenProductPopup(false)}
                                              cursor={cursor}
                                              popupData={popupData}/> :  ''
        }
    </>
    )
}