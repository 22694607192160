import {useRef} from "react";
import {isEmpty} from "lodash";

import s from './Navigation.module.scss'

import {useProductsStore} from "../../store/products/products";
import {useOrderStore} from "../../store/order/order";
import {useAppStore} from "../../store/app/app";


export const Navigation = () => {
    const {products} = useProductsStore()
    const {orderData} = useOrderStore()
    const {setCartPopupOpen} = useAppStore()

    const handleClick = (i) => {
        var element = document.getElementById(`${i}`);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    const wrapperRef = useRef();

    return (
        <nav className={s.navWrapper} ref={wrapperRef}
            style={{

                height: '54px',
            }}
        >
            <div className={s.nav}
                style={{
                    overflowX: 'hidden',
            }}
            >


                <div style={{
                    transition: '0.3s'
                }}>
                    {products.length ? products.map((item, i) => {
                        return <span key={item.type} className={s.navCategoryItem}
                                     onClick={() => handleClick(i)}
                        >{item.type}</span>
                    }) : ''}
                </div>

                <div style={{flex: 1}}></div>

                <button className={s.cart} onClick={() => setCartPopupOpen(true)}>
                    {!isEmpty(orderData) ? orderData.order.product_count > 0 ? `${orderData.order.product_count} | ` : '' : ''} Корзина
                </button>
            </div>

        </nav>
    )
}