
import {Transition} from "react-transition-group";
import {isEmpty} from "lodash";

import s from './ActiveOrdersPopup.module.scss'

import exitSvg from '../../public/exit.svg'
import {useOrderStore} from "../../store/order/order";


export default function ActiveOrdersPopup({opened, onClose}) {
    const {activeOrders, haveActiveOrders} = useOrderStore()

    const onCloseWrapper = (event) => {
        if (event.target.classList.contains('cart-overlay')) onClose()
    }
    if (haveActiveOrders) {
        return (
            // <Portal>
            <Transition
                in={opened}
                timeout={200}
                unmountOnExit={true}
            >
                {(state) => (
                    <div className={`cart-container ${state}`}>
                        <div className='cart-overlay' onClick={onCloseWrapper}/>
                        <div className={`cart-content cart-content-${state}`} style={{paddingBottom: '10px'}}>
                            <section className='cart-title-section'>
                                <label className='cart-title'>
                                    <span className='cart-title-text'>Активные заказы</span>
                                    <button className='cart-exit' onClick={onClose}>
                                        <img src={exitSvg} alt={''} height={25}/>
                                    </button>
                                </label>
                            </section>
                                <div className={s.ordersWrapper}>
                                    {activeOrders.map((order, index) => {
                                        return (<ActiveOrder order={order} key={index}/>)
                                    })}
                                </div>


                        </div>
                    </div>
                    )
                }
            </Transition>
            // </Portal>
        )
    } else {
        return <></>
    }
}


const ActiveOrder = ({order}) => {
    console.log(order)
    return (
        <div className={s.wrapper}>
            {!isEmpty(order.items.address.street) &&
                <div className={s.address}>
                Доставка по адресу: {order.items.address.street}
            {order.items.address.entrance.length ? `, подъезд ${order.items.address.entrance}` : ''}
            {order.items.address.floor.length ? `, этаж ${order.items.address.floor}` : ''}
            {order.items.address.apartment.length ? `, кв. ${order.items.address.apartment}` : ''}
                </div>
            }


<div className={s.statusTitle}>
    <span>Статус заказа №{order.order_id}</span>
    <span>{order.logs.created_at}</span>
</div>

<div className={s.road}>
    {/*<div className={order.status === 6 ? s.roadRow : s.roadRowDisactive}>*/}
    <div className={s.roadRow}>
        <div className={s.time}>{order.logs.created_at}</div>
                    <div className={s.lineWrapper}>
                        <div className={s.circle}></div>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.orderStatus}>Ожидает подтверждения</div>
                </div>
                <div className={order.status >= 0 && order.status !== 6 ? s.roadRow : s.roadRowDisactive}>
                    <div className={s.time}>{order.status !== 6 && order.logs.created_at}</div>
                    <div className={s.lineWrapper}>
                        <div className={s.circle}></div>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.orderStatus}>Принят</div>
                </div>
                <div className={order.status >= 1 && order.status !== 6 ? s.roadRow : s.roadRowDisactive}>
                    <div className={s.time}>{order.logs.start_cooking}</div>
                    <div className={s.lineWrapper}>
                        <div className={s.circle}></div>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.orderStatus}>Готовится</div>
                </div>
                <div className={order.status >= 3 && order.status !== 6 ? s.roadRow : s.roadRowDisactive}>
                    <div className={s.time}>{order.logs.start_delivering}</div>
                    <div className={s.lineWrapper}>
                        <div className={s.circle}></div>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.orderStatus}>Доставляется</div>
                </div>
                <div className={order.status === 4 && order.status !== 6 ? s.roadRow : s.roadRowDisactive}>
                    <div className={s.time}>{order.logs.success_completion_at}</div>
                    <div className={s.lineWrapper}>
                        <div className={s.circle}></div>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.orderStatus}>Доставлен</div>
                </div>
            </div>

            <div className={s.itemsWrapper}>
                {order.items.promocode.type === 1 && <PromocodeProduct item={order.items.promocode.item}/>}
                {order.items.items.map((item, index) => {
                    return (
                        <div className={s.item} key={index}>
                            {/*<div className={s.leftPart}></div>*/}
                            <img src={item.img} alt={`Доставка пиццы Поскореев. Пицца ${item.title}`}/>
                            <div className={s.itemTitle}>
                                <span>{item.title}</span>
                                <span>{item.size}{item.unit}</span>
                            </div>
                            <div className={s.itemCount}>{item.quantity}</div>
                            <div className={s.itemPrice}>{item.sum}р</div>

                        </div>
                    )
                })}
            </div>


            <div className={s.total}>
                <span>Итого: </span>
                <span>{order.items.total_sum}р</span>
            </div>

            <div className={s.cancelOrder}>Отменить заказ можно звонком оператору</div>
        </div>
    )
}


const PromocodeProduct = ({item}) => {
    return (
        <div className={s.item} key={item.id}>
            <img src={item.img} alt={`Доставка пиццы Поскореев. Пицца ${item.title}`}/>
            <div className={s.itemTitle}>
                <span>{item.title}</span>
                <span>{item.size}{item.unit}</span>
            </div>
            {/*<div className={s.itemCount}>{item.quantity}</div>*/}
            <div className={s.itemPrice}>{item.price}р</div>

        </div>
    )
}