import axios from "axios";
import {url} from "../../config";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import './Checkout.css'
import s from "../../components/MobileMenu.module.scss";

import {useOrderStore} from "../../store/order/order";
import {useRestaurantStore} from "../../store/restaurant/restaurant";

import back from '../../public/backButton.svg'
import {useUserStore} from "../../store/user/user";
import LoginComponent from "../../components/login/Login";


import {useAppStore} from "../../store/app/app";



export const Checkout = () => {
    const {orderData, getOrderAPI,
        entrance, setEntrance, appartment, setAppartment,
    floor, setFloor, comment, setComment,
        ChoosePaytypeAPI, setHaveActiveOrders, setActiveOrdersForAPI,
        clearOrderData} = useOrderStore()
    const {restaurantData, getRestaurantInfoAPI, restaurantPaytypes, getRestaurantPaytypesAPI,
         setPaytypeId, paytypeId, setPaytypeCursor} = useRestaurantStore()
    const {isOpenLoginPopup, setIsOpenLoginPopup} = useUserStore()
    const {setCartPopupOpen} = useAppStore()

    const [isLoadFinishOrder, setIsLoadFinishOrder] = useState(false)

    useEffect(() => {
        getOrderAPI()
        getRestaurantInfoAPI()
        getRestaurantPaytypesAPI()
    }, [])



    async function CheckActiveOrdersAPI (){

        await axios.get(`${url}/api/v1/orders/checkActiveOrders`).then(function (response) {
            if (response.data.status) {
                if (!response.data.data.haveActiveOrders) {
                    setHaveActiveOrders(false)
                    setActiveOrdersForAPI([])
                } else {
                    setHaveActiveOrders(true)
                    setActiveOrdersForAPI(response.data.data.orders)
                }
            }
        }).catch(function () {
            setHaveActiveOrders(false)
            setActiveOrdersForAPI([])
        })
    }

    const navigate = useNavigate()
    async function FinishOrderAPI(comment = '',
                                         entrance = '', appartment = '',
                                         floor = ''){

        await axios.post(`${url}/api/v1/orders/finishOrder?comment=${comment}&entrance=${entrance}&appartment=${appartment}&floor=${floor}`).then(function (response) {
            if (response.data.status) {
                setPaytypeCursor(0)
                setPaytypeId(undefined)
                clearOrderData()
                setCartPopupOpen(false)
                CheckActiveOrdersAPI()
                setPaytypeId(undefined)
                navigate('/')
            } else {
                if (response.data.errorCode === 101) {
                    setIsOpenLoginPopup(true)
                } else {
                    alert(response.data.errorMessage)
                }
            }
        })
    }

    return (
        <>
        <main className='checkout-wrapper'>

            <section className='checkout'>
                <Link to={'/'} className={s.mobileBackButton} >
                    <img src={back} alt="Вернуться назад"/>
                    <span>Назад</span>
                </Link>
                <h1 className='checkout-title'>Оформление заказа</h1>
                {
                    !isEmpty(orderData) &&
                    <div className='checkout-info'>
                        <section className='checkout-info-wrapper'>
                            <div className='checkout-input-block-street'>
                                <h3 className='checkout-subtitle'>Улица</h3>
                                <div className='checkout-block-input'>{restaurantData.client_address}</div>
                            </div>
                            <div className='checkout-input-row'>
                                <div className='checkout-input-block'>
                                    <h3 className='checkout-subtitle'>Подъезд</h3>
                                    <input className='checkout-input'
                                           value={entrance}
                                           onChange={(e) => setEntrance(e.target.value)}/>
                                </div>
                                <div className='checkout-input-block'>
                                    <h3 className='checkout-subtitle'>Этаж</h3>
                                    <input className='checkout-input'
                                           value={floor}
                                           onChange={(e) => setFloor(e.target.value)}/>
                                </div>
                            </div>
                            <div className='checkout-input-row'>

                                <div className='checkout-input-block'>
                                    <h3 className='checkout-subtitle'>Квартира</h3>
                                    <input className='checkout-input'
                                           value={appartment}
                                           onChange={(e) => setAppartment(e.target.value)}/>
                                </div>
                            </div>
                            <div style={{marginTop: 30}}>
                                <h3 className='checkout-subtitle'>Комментарий для курьера</h3>
                                <input className='checkout-input-comment'
                                       value={comment}
                                       onChange={(e) => setComment(e.target.value)}
                                       style={{cursor: 'pointer'}}/>
                            </div>

                            <div className='checkout-paytypes'>
                                <h3 className='checkout-subtitle'>Способ оплаты</h3>
                                <div className='slider'>
                                    {
                                        restaurantPaytypes.map((item, i) => {
                                            return (
                                                <div key={i}
                                                     className={paytypeId === item.id ? 'sliderItem activeSlider' : 'sliderItem'}
                                                     onClick={() => ChoosePaytypeAPI(item.id, setPaytypeId)}
                                                >{item.name}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <aside className='checkout-buttons'>
                                <Link to={'/'} className='checkout-back-button'>
                                    <span>&#60;</span>
                                    Вернуться назад
                                </Link>
                                <button
                                    className='checkout-confirm-button'
                                    onClick={async () => {
                                        setIsLoadFinishOrder(true)
                                        await FinishOrderAPI(comment, entrance, appartment, floor)
                                        setIsLoadFinishOrder(false)
                                    }}
                                    disabled={isLoadFinishOrder}
                                >
                                    Оформить заказ
                                    <span>&#62;</span>
                                </button>
                            </aside>
                            <aside className={s.wrapper}>
                                <div className={s.menu}>

                                    <div className={s.total_price}>
                                        <span>Сумма заказа</span>
                                        <span>{orderData.order.total_sum}р</span>
                                    </div>

                                    <button
                                        className={s.confirm_button}
                                        onClick={async () => {
                                            setIsLoadFinishOrder(true)
                                            await FinishOrderAPI(comment, entrance, appartment, floor)
                                            setIsLoadFinishOrder(false)
                                        }}
                                        disabled={isLoadFinishOrder}
                                    >
                                        Оформить заказ
                                        <span>&#62;</span>
                                    </button>
                                </div>


                            </aside>
                        </section>

                        <section className='checkout-check-wrapper'>
                            <aside className='checkout-check'>
                                <div className='check-top'>
                                    <span className='check-title'>Ваш заказ</span>
                                    <div className='check-items'>
                                        {orderData.promocode.applied && orderData.promocode.linked
                                            && orderData.promocode.type === 1 && <PromocodeProduct item={orderData.promocode.effect}/>}
                                        {
                                            orderData.order.items.map((item, i) => {
                                                return (
                                                    <div key={i} className='check-item'>
                                                        <div className='check-item-left'>
                                                            <p>{item.title}</p>
                                                            <p className='check-item-product-info'>{item.size}{item.unit}</p>
                                                        </div>
                                                        <div className='check-item-right'>
                                                            {item.quantity > 1 ? `${item.quantity} x ` : ''}{Number(item.sum) / Number(item.quantity)}р
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div className='check-bottom'>
                                    <div className='check-subtotal'>
                                        <div className='check-subtotal-item'>
                                            <span>Доставка</span>
                                            <span>Бесплатно</span>
                                        </div>
                                        {
                                            orderData.order.total_sum < orderData.order.sum &&
                                                <div className='check-subtotal-item'>
                                                    <span>Ваша скидка</span>
                                                    <span>{Math.round(Number(orderData.order.sum) - Number(orderData.order.total_sum))}р</span>
                                                    </div>
                                            }

                                        </div>
                                        <div className='check-total'>
                                            <span>Сумма заказа</span>
                                            <span>{orderData.order.total_sum}р</span>
                                        </div>
                                    </div>
                                </aside>
                            </section>
                    </div>
                }

            </section>

        </main>
        <LoginComponent opened={isOpenLoginPopup} onClose={() => setIsOpenLoginPopup(false)}/>
        </>
    )
}

const PromocodeProduct = ({item}) => {
    return (
        <div key={item.id} className='check-item'>
            <div className='check-item-left'>
                <p>{item.title}</p>
                <p className='check-item-product-info'>{item.size}{item.unit}</p>
            </div>
            <div className='check-item-right'>
                {item.price}р
            </div>
        </div>
    )
}

