import {useEffect, useState} from "react";
import {Transition} from "react-transition-group";
import axios from "axios";
import {url} from "../../config";


import './ProductCart.css'
import s from './ProductCart.module.scss'


import {useRestaurantStore} from "../../store/restaurant/restaurant";
import {useOrderStore} from "../../store/order/order";
import {useAppStore} from "../../store/app/app";



// import Portal from "@/hooks/Portal";


export default function ProductCart({opened, onClose, cursor, popupData}) {
    const [itemID, setItemId] = useState(0)
    const {setOrderData} = useOrderStore()
    const {setIsPickStreetPopupOpen} = useRestaurantStore()
    const {setCartToast, setCartToastHash} = useAppStore()
    useEffect(() => (
        setItemId(cursor)
    ), [])

    async function AddProductToCart (id, title){
        await axios.post(`${url}/api/v1/orders/addToOrder?menu_id=${id}`).then(function (response) {
            if (response.data.status) {
                setOrderData(response.data.data)
                setCartToast(title)
                setCartToastHash()
                onClose()
            } else {
                if (response.data.errorCode === 206 || response.data.errorCode === 100 ) {
                    setIsPickStreetPopupOpen(true)
                } else {
                    alert(response.data.errorMessage)
                }
            }
        })
    }

    return (

        <Transition
            in={opened}
            timeout={200}
            unmountOnExit={true}
        >
            {(state) => (
                <div className={`product-popup-container ${state}`}>
                    <div className='product-popup-overlay' onClick={() => onClose()}/>
                    <div className={`product-popup-content`}>

                        <img src={popupData.img} alt={`${popupData.title} ${popupData.description}`} className={s.img}/>

                        <div className={s.info}>
                            <span className={s.title}>{popupData.title}</span>
                        </div>

                        <div className={s.description}>{popupData.description}</div>

                        <div className={s.slider}>
                            {
                                popupData.sizes.map((item, i) => {
                                    return (
                                        <div key={item}
                                             className={!popupData.in_stock[i] ? s.sliderItem_none : i === itemID ? s.sliderItem_active : s.sliderItem}
                                             onClick={() => !popupData.in_stock[i] ? '':setItemId(i)}
                                        >{`${item}${popupData.units[i]}`}</div>
                                    )
                                })
                            }
                        </div>
                        <div className={s.footer}>
                            <span className={s.price}>{popupData.prices[itemID]}р</span>
                            <button className={s.button} onClick={() => {
                                const infostr = `${popupData.title}, ${popupData.sizes[itemID]} ${popupData.units[itemID]}`
                                AddProductToCart(popupData.id[itemID], infostr)
                            }}>Добавить
                            </button>
                        </div>

                    </div>
                </div>
            )}


        </Transition>

    )
}

