import { Outlet } from 'react-router-dom';

// import {Header} from "../components/header/Header";
import {Header} from "../components/newHeader/Header";
import React from "react";
import Footer from "../components/footer/Footer";
import {Navigation} from "../components/Navigation/Navigation";

const Layout = () => {
    return (
        <>
            <Header/>
            <Navigation />
            <Outlet/>
            <Footer/>
        </>
    )
}

export {Layout}