import axios from 'axios'

import {url} from '../../config'

axios.defaults.withCredentials = true
export async function GetRestaurantsInfo (number){
    return await axios.get(`${url}/api/v1/restaurants/`)
}

export async function GetRestaurantsPaytypes (number){
    return await axios.get(`${url}/api/v1/restaurants/paytypes`)
}
