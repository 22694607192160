import {Transition} from "react-transition-group";
import {useEffect, useState} from "react";

import './CartToast.css'
import {useAppStore} from "../../store/app/app";


export default function CartToast ({message}) {
    const [open, setOpen] = useState(false)
    const {headerHeight,  cartToastHash} = useAppStore()

    useEffect(() => {
        setOpen(false)
        setOpen(true)
        const timeoutId = setTimeout(() => {
            setOpen(false)
        }, 2500)
        return () => clearTimeout(timeoutId)
    }, [cartToastHash])

    if (cartToastHash !== 0) {
        return (
            <Transition
                in={open}
                timeout={500}
                unmountOnExit={true}
            >
                {(state) => (
                        <div className={`cart-toast-wrapper ${state}-toast`} header-height={`${headerHeight}px`} > Добавлено:<br/>{message}</div>

                    )
                }
            </Transition>
        )
    }

}